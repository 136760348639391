<template>
  <div class="tasks">
    <div class="tasks__title">
      <img src="@/assets/img/header-title.webp" alt="" />
      <div class="container tasks__text">
        <span>ЗАДАНИЯ</span>
        <p>
          Получи эксклюзивный мерч «ЛИГА ФОРУМ» <br />
          <br />
          Выполняй задания мотивационной программы, войди в ТОП-30 участников
          форума и стань обладателем нашей лимитированной продукции.<br />
          <br />
          Баллы остальных участников, не вошедших в топ, конвертируются в
          LIGA-рубли, которые можно потратить на мерч, подарки от партнеров и
          форумные ценности в магазине LIGA STORE во время смены.<br />
          <br />
          Эти задания не обязательны к выполнению.
        </p>
      </div>
    </div>
    <Preloader v-if="isDataLoading" />
    <div class="container" v-else>
      <div class="tasks__content" :key="renderKey">
        <div
          class="tasks-item"
          v-for="(item, index) in infoReformatted2"
          :key="index"
        >
          <p>
            {{ item.title }}
          </p>
          <div class="tasks-item__form">
            <div class="tasks-item__input">
              <Input
                :label="item.description"
                type="text"
                v-model="model[index]"
                :error="item.current === index"
                :disabled="item.is_done === true"
                @update:modelValue="
                  (value) => {
                    item.modelChanged = true;
                    !value || !/[^\s]+/.test(value)
                      ? (item.modelEmpty = true)
                      : (item.modelEmpty = false);
                  }
                "
              />
              <div class="tasks-item__errors">
                <span
                  v-for="error in errors"
                  :key="error"
                  v-show="item.current === index"
                >
                  {{ error.error_descr }}
                </span>
              </div>
              <div class="tasks-item__rated" v-show="item.is_done === true">
                <p>Данное задание уже оценено</p>
              </div>
              <div
                class="tasks-item__rated"
                v-show="item.is_done === false"
                :class="{ 'tasks-item__rated--black': item.is_done === false }"
              >
                <p>Данное задание не проверено</p>
              </div>
            </div>
            <Button
              blue
              @click="handleSubmit(item, index)"
              :loading="item.isLoading"
              :disabled="
                item.is_done === true || item.modelEmpty || !item.modelChanged
              "
            >
              СОХРАНИТЬ
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import Preloader from "@/components/Blocks/Preloader";
export default {
  name: "LkTasks",
  components: { Preloader, Button, Input },
  data() {
    return {
      info: [],
      infoAnswers: [],
      isLoading: false,
      errors: [],
      isSaved: false,
      model: [],
      modelChanged: false,
      modelEmpty: false,
      mainInfo: {},
      isDataLoading: false,
      renderKey: 0,
    };
  },
  watch: {
    model(newValue) {
      this.modelChanged = true;
      !newValue ? (this.modelEmpty = true) : (this.modelEmpty = false);
    },
    infoAnswers() {
      this.renderKey += 1;
    },
    infoReformatted2() {
      this.model = this.infoReformatted2.map((item) => item.url);
    },
  },
  computed: {
    infoReformatted() {
      let map = this.infoAnswers?.reduce(
        (acc, { task_id, value }) => ((acc[task_id] = value), acc),
        {}
      );
      return this.info?.map(({ id, ...rest }) =>
        Object.assign({ id }, rest, map[id] ? { url: map[id] } : {})
      );
    },
    infoReformatted2() {
      let map = this.infoAnswers?.reduce(
        (acc, { task_id, is_done }) => ((acc[task_id] = is_done), acc),
        {}
      );
      return this.infoReformatted?.map(({ id, ...rest }) =>
        Object.assign(
          { id },
          rest,
          map[id] ? { is_done: true } : { is_done: map[id] }
        )
      );
    },
  },
  methods: {
    async handleSubmit(item, index) {
      item.isLoading = true;
      item.current = index;
      await createRequest(requestConfigs.POSTTasksSave, {
        jsonPayload: {
          task_id: item.id,
          value: this.model[index],
        },
        routerPayload: {
          id: item.id,
        },
      })
        .then(() => {
          this.errors = 0;
          createRequest(requestConfigs.GETListTasks).then((result) => {
            this.info = result.data.list_tasks.data;
            this.infoAnswers = result.data.list_tasks_answers.data;
          });
        })
        .finally(() => {
          item.isLoading = false;
          item.modelChanged = false;
        })
        .catch((error) => {
          this.errors = error.errors;
        });
    },
  },
  beforeMount() {
    this.isDataLoading = true;
    createRequest(requestConfigs.GETListTasks)
      .then((result) => {
        this.info = result.data.list_tasks?.data;
        this.infoAnswers = result.data.list_tasks_answers?.data.sort((a, b) =>
          a.task_id > b.task_id ? 1 : -1
        );
      })
      .finally(() => {
        this.isDataLoading = false;
      });
  },
};
</script>

<style lang="scss">
.tasks {
  &__title {
    display: block;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 60px;
    img {
      width: 100%;
      max-height: 620px;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    color: var(--white);
    span {
      font-weight: 700;
      font-size: 50px;
      line-height: 75px;
      margin-bottom: 60px;
      display: inline-block;
    }
    p {
      max-width: 500px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  &-item {
    max-width: 1125px;
    p {
      @include text20();
      color: var(--black2);
      font-weight: 600;
      margin-bottom: 20px;
    }
    &__form {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      button {
        margin-top: 8px;
      }
    }
    .input--error {
      input {
        margin-bottom: 0;
      }
    }
    &__input {
      flex-grow: 1;
      position: relative;
      span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: var(--red);
      }
      //.input {
      //  label {
      //    transform: translateY(0);
      //    font-size: 14px;
      //    line-height: 19px;
      //  }
      //}
    }
    &__errors {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &__rated {
      p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 17px;
        color: var(--green);
        font-weight: 600;
      }
      &--black {
        p {
          color: var(--black2);
        }
      }
    }
  }

  @include adaptive(tablet-small) {
    &__text {
      position: unset;
      color: var(--black2);
      span {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__title {
      img {
        display: none;
      }
    }
    &-item {
      &__form {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        .input {
          width: 100%;
        }
      }
      &__input {
        width: 100%;
      }
    }
  }
}
.winter {
  .tasks {
    &__title {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url("~@/assets/img/snowflakes.svg");
      }
    }
  }
}
</style>
