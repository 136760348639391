// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/snowflakes.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tasks__title{display:block;max-width:1920px;width:100%;margin:0 auto;position:relative;margin-bottom:60px}.tasks__title img{width:100%;max-height:620px;height:100%;-o-object-fit:cover;object-fit:cover}.tasks__text{position:absolute;top:140px;left:0;right:0;color:var(--white)}.tasks__text span{font-weight:700;font-size:50px;line-height:75px;margin-bottom:60px;display:inline-block}.tasks__text p{max-width:500px}.tasks__content{display:flex;flex-direction:column;gap:40px}.tasks-item{max-width:1125px}.tasks-item p{font-size:20px;line-height:30px;color:var(--black2);font-weight:600;margin-bottom:20px}@media screen and (max-width:479px){.tasks-item p{font-size:14px;line-height:17px}}.tasks-item__form{display:flex;align-items:flex-start;gap:25px}.tasks-item__form button{margin-top:8px}.tasks-item .input--error input{margin-bottom:0}.tasks-item__input{flex-grow:1;position:relative}.tasks-item__input span{font-size:12px;line-height:14px;font-weight:600;color:var(--red)}.tasks-item__errors{display:flex;flex-direction:column;gap:5px}.tasks-item__rated p{margin-top:5px;font-size:14px;line-height:17px;color:var(--green);font-weight:600}.tasks-item__rated--black p{color:var(--black2)}@media screen and (max-width:767px){.tasks__text{position:unset;color:var(--black2)}.tasks__text span{margin-bottom:10px;font-size:18px;line-height:22px}.tasks__title img{display:none}.tasks-item__form{flex-direction:column;align-items:flex-start;gap:10px}.tasks-item__form .input,.tasks-item__input{width:100%}}.winter .tasks__title:after{content:\"\";position:absolute;left:0;right:0;top:0;bottom:0;width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", ""]);
// Exports
module.exports = exports;
